.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid #adb5db;
  padding: 10px;
  border-radius: 6px;
  .flex {
    display: flex;
    align-items: center;
    justify-content: end;
    .icon-delete {
      width: 35px;
      height: 35px;
      background: rgba(213, 77, 74, 0.25);
      border-radius: 35px;
      padding-left: 8px;
      margin-left: 8px;
      .margin {
        margin-top: 7px;
      }
    }
    .icon-edit {
      width: 35px;
      height: 35px;
      background: rgba(237, 168, 66, 0.25);
      border-radius: 35px;
      padding-left: 8px;
      .margin {
        margin-left: 3px;
        margin-top: 10px;
      }
    }
  }

  .imgWrapper {
    width: 120px;
    margin: 0 auto;
  }

  .img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 120px;
    border: 1px solid var(--border-color);
  }

  .itemTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .status {
    border: 1px solid #0077b6;
    margin-left: 12px;
    width: 31px;
    height: 31px;
    border-radius: 28px;
    .icon {
      width: 25px;
      height: 25px;
      background: #0077b6;
      border: 1px solid #0077b6;
      border-radius: 25px;
      border: none;
      margin-top: 2px;
      margin-left: 2px;
    }
  }

  .notStatus {
    border: 1px solid #6c757d;
    margin-left: 12px;
    width: 31px;
    height: 31px;
    border-radius: 28px;
    .iconBox {
      width: 25px;
      height: 25px;
      background: #6c757d;
      border: 1px solid #6c757d;
      border-radius: 25px;
      border: none;
      margin-top: 2px;
      margin-left: 2px;
    }
  }
}

.itContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 34px;
}
