.pickerImageGroup {
  margin-right: 1rem;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  position: relative;
}

.img {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  object-fit: cover;
  border: 1px solid var(--border-color);
  max-width: none;
  position: absolute;
  top: 0;
  left: 0;
}

.editInputPicker {
  position: absolute;
  width: 16px;
  height: 16px;
  right: -10px;
  top: -5px;
}

.labelInputPiacker {
  width: 100%;
  height: 100%;
  border: 1px solid var(--border-color);
  border-radius: 35px;
  display: grid;
}

.labelInputPiacker.loading {
  border: none;
}

.inputPickerImage {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uploadIcon {
  align-self: center;
  justify-self: center;
  width: 16px;
}
