.cbHederCombineService {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 1fr;
  column-gap: 1rem;
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 0.25rem;
  line-height: 50px;
  padding: 0 1rem;
  margin-bottom: var(--mb-1);
}

.cbAction {
  text-align: right;
}

.cbAction,
.cbHead,
.cbStatus,
.cbOffer {
  font-weight: var(--font-semi-bold);
  font-size: var(--normal-font-size);
  color: var(--text-color);
}

@media screen and (max-width: 768px) {
  .cbAction,
  .cbStatus {
    display: none;
  }

  .cbHederCombineService {
    grid-template-columns: 2fr 1fr 30px;
  }
}

@media screen and (max-width: 480px) {
  .cbHederCombineService {
    grid-template-columns: 50% 1fr;
  }

  // .cbAction {
  //   display: none;
  // }
}
