.wrapperContent {
  position: fixed;
  background: var(--text-color);
  opacity: 0.6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-overlay);
}

.cbCombineContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  width: 450px;
  background: var(--body-color);
  border-radius: 24px 0px 0px 24px;
  overflow: auto;
  transform: translateX(100%);
  transition: all 0.4s ease;
  // z-index: var(--z-fixed);
  z-index: var(--z-modal);
}

.cbCombineContainerShow {
  transform: translateX(0);
}

.cbContent {
  height: 100%;
  overflow: auto;
}

.cbContent::-webkit-scrollbar {
  width: 0;
}

.cbClose {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--mb-1);
}

.cbButton {
  color: var(--title-color);
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cbButton img {
  width: 100%;
  height: 100%;
}

.cbFormWrap {
  .crInputGroup {
    height: calc(100vh - 248px);
    overflow-y: auto;
  }

  .crFormInput:last-child {
    margin-bottom: 0;
  }

  .crFormTextarea {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--mb-1-5);

    .crTitleTextarea {
      font-weight: var(--font-semi-bold);
      color: var(--text-color);
      margin-bottom: 0.5rem;
      line-height: normal;
    }
  }
}

.cbTitle {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  line-height: normal;
  color: var(--title-color);
  margin-bottom: var(--mb-2);
}

.cbWrapperBtn {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--mb-1-5);
}

.btnUpdate {
  height: 46px;
  padding: 0 1.5rem;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 0.25rem;
  color: var(--body-color);
}

.crFormInput {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mb-1-5);
}

.crTitleInput {
  color: var(--text-color);
  font-weight: var(--font-semi-bold);
  margin-bottom: var(--mb-0-5);
  line-height: normal;
}

.crValueInput {
  height: 46px;
  border: 1px solid var(--border-color);
  color: var(--text-color);
  border-radius: 0.25rem;
  padding: 0 1rem;
}

.crValueInput:focus,
.crValueTextarea:focus {
  outline: none;
}

.crValueTextarea {
  min-height: 110px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  padding: 14.03px 1rem;
}

.crValueInput::-webkit-outer-spin-button,
.crValueInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 480px) {
  .cbCombineContainerShow {
    width: 100%;
    border-radius: 0;
  }
}
