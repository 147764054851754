.itHhHdHouseHoldImg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--mb-1-5);

  img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    border: 1px solid var(--border-color);
    object-fit: cover;
  }
}
.itHdTableBlock {
  padding: 1rem;
  border: 1px solid #adb5db;
  border-radius: 0.25rem;
}

.itHhHdTableItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-1);
}

.itHhItemName {
  max-width: -webkit-fill-available;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itHhHdItemHouseHold {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: var(--mb-1);

  .itHhHdBtnGroupEdit,
  .itHhHdBtnGroupDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .itHhHdBtnGroupEdit {
    width: 35px;
    height: 35px;
    background-color: var(--edit-background-color);
    border-radius: 35px;
  }
  .itHhHdBtnGroupDelete {
    width: 35px;
    height: 35px;
    background-color: var(--delete-background-color);
    border-radius: 35px;
    margin-left: 0.5rem;
  }
}
.connectedStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #31a24c;
  box-shadow: 0px 0px 5px #31a24c;
}
.notConnectStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: gray;
  box-shadow: 0px 0px 5px gray;
}
.disconnectStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: red;
  box-shadow: 0px 0px 5px red;
}
.nameHousehold {
  padding-right: 4px;
}
