.tbWrapCombine {
  display: flex;
  column-gap: 20px;
  height: 100%;
}

.tbCombine {
  width: 100%;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
}

.tbContent {
  padding: 1rem;
}

.tbInput {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 1rem;
}

.tbItemCombine {
  // height: calc(100% - 61px);
  height: calc(100% - 59px);
  overflow-y: auto;
}

.tbItemCombine::-webkit-scrollbar {
  width: 0;
}

.tbBtnCombine {
  color: var(--text-color-light);
  background-color: var(--border-color);
  border-radius: 0.25rem;
  opacity: 0.6;
}

.tbBtnCombineSuccess {
  padding: 0 1rem;
  border-radius: 0.25rem;
  color: var(--body-color);
  background-color: var(--first-color);
}

.tbWrapForm {
  margin-bottom: var(--mb-1);
}

.tbWrapElement {
  cursor: pointer;
}

.tbWrapElement,
.tbWrapElementOffer {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0 1rem;
  margin-bottom: var(--mb-1);
  height: 50px;
}

.tbWrapElementOffer {
  justify-content: space-between;
}

.tbWrapElement:last-child,
.tbWrapElementOffer:last-child {
  margin-bottom: 0;
}

.tbWrapperContent {
  display: flex;
  align-items: center;
}

.tbSearch {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--mb-1);
}

.tbSearch form {
  width: 100%;
}

.tbAvatar {
  width: 35px;
  height: 35px;
  border-radius: 40px;
  object-fit: cover;
  margin-right: 1rem;
  border: 1px solid var(--border-color);
}

.tbValueCombine {
  max-width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-color);
}

.tbBtnClose {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: transparent;
  transition: 0.3s ease;
  cursor: pointer;
}

.tbBtnClose:hover {
  background-color: #eee;
}

@media screen and (max-width: 768px) {
  .tbWrapCombine {
    flex-direction: column-reverse;
  }

  .tbCombine {
    margin-bottom: var(--mb-1);
  }
}
