@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --header-height: 4.75rem;

  // Colors
  --first-color: hsl(201, 100%, 36%);
  --first-color-alt: hsl(201, 100%, 35%);
  --first-color-light: hsl(227, 100%, 95%);
  --title-color: hsl(210, 11%, 15%);
  --text-color: hsl(210, 10%, 23%);
  --text-color-light: hsl(208, 7%, 46%);
  --border-color: hsl(210, 11%, 71%);
  --body-color: #fff;
  --connected-color: #31a24c;

  --edit-color: hsl(36, 83%, 59%);
  --edit-background-color: hsl(35, 84%, 90%);
  --delete-color: hsl(1, 62%, 56%);
  --delete-background-color: hsl(2, 61%, 89%);

  // Fonts
  --body-font: 'Poppins', sans-serif;

  --biggest-font-size: 2.5rem; // 40px
  --h1-font-size: 2.25rem; // 36px
  --h2-font-size: 1.5rem; // 24px
  --h3-font-size: 1.25rem; // 20px
  --normal-font-size: 1rem; // 16px
  --small-font-size: 0.875rem; // 14px
  --smaller-font-size: 0.75rem; // 12px

  // FontWeight
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;

  // Margin
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

  // Z-index
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-overlay: 999;
  --z-modal: 1000;
  --z-loading: 10000;

  // Responsive break point
  --mobile-width: 480px;
  --tablet-width: 768px;
  --laptop-width: 1024px;
  --desktop-width: 1200px;
}

// Responsive typography
@media screen and (max-width: 1024px) {
  :root {
    --header-height: 3.5rem;

    --biggest-font-size: 2rem; // 32px
    --h1-font-size: 1.5rem; // 24px
    --h2-font-size: 1.25rem; // 20px
    --h3-font-size: 1rem; // 16px
    --normal-font-size: 0.975rem; // 15px
    --small-font-size: 0.875rem; // 14px
    --smaller-font-size: 0.75rem; // 12px
  }
}

// Start Base
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

body {
  // margin: var(--header-height) 0 0 0;
  background-color: var(--body-color);
  transition: 0.4s;
  text-rendering: optimizeSpeed;
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

.container {
  max-width: 992px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
}

.main {
  overflow: hidden;
}

// Scroll
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #eee;
}

::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--first-color-alt);
}

// End Base

// Start Mixin

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin img-full-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// Responsive

@mixin mobile {
  @media screen and (max-width: var(--mobile-width)) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: var(--tablet-width)) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: var(--laptop-width)) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: var(--desktop-width)) {
    @content;
  }
}

// End Mixin

.required:after {
  content: ' *';
  color: var(--delete-color);
}

.label {
  margin-bottom: 5px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
