.crHouseContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  width: 450px;
  height: 100%;
  background: white;
  border-radius: 1.5rem 0px 0px 1.5rem;
  overflow: auto;
  transform: translateX(100%);
  transition: all 0.4s ease;
  z-index: var(--z-modal);
}

.crHouseContainerShow {
  transform: translateX(0);
}

.crClose {
  display: flex;
  justify-content: flex-end;

  .crButton {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.crContent {
  height: 100%;
  overflow-y: hidden;
}

.crContent::-webkit-scrollbar {
  width: 0;
}

.crTitle {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  display: block;
  margin-bottom: var(--mb-2);
}

.crFormWrap {
  margin-top: 13px;
  height: calc(100% - 90px);
  overflow: auto;
  padding-bottom: 62px;
}

.crFormWrap::-webkit-scrollbar {
  width: 0;
}

.crFormInput {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mb-1-5);
}

.crFormInput:last-child {
  margin-bottom: 0;
}

.crValueInput {
  color: var(--text-color);
  width: 100%;
  height: 43px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0 1rem;
}

.crValuePostalCode {
  text-transform: uppercase;
}

.crProvince {
  text-transform: uppercase;
}

.crValueInput::-webkit-outer-spin-button,
.crValueInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.crValueInput:focus {
  outline: none;
}

.crTitleInput {
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
  margin-bottom: var(--mb-0-5);
}

.crFormInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.crCountryCode {
  width: 55px;
  height: 43px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 10px;
  outline: none;
}

.hhCrButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  padding: 16px 32px 32px;
  background-color: white;
  left: 0;
  right: 0;
  bottom: 0;
}

.btnCreate {
  height: 46px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 0.25rem;
  color: white;
  padding: 0 1.5rem;
}

@media screen and (max-width: 480px) {
  .crHouseContainer {
    width: 100%;
    border-radius: 0;
  }
}
