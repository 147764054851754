.cbTableItem {
  display: grid;
  grid-template-columns: 6fr 2fr 2fr 1fr;
  column-gap: 1rem;
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 0.25rem;
  line-height: 50px;
  padding: 0 1rem;
  margin-bottom: var(--mb-1);
}

.itCbItemCombineService {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 -8px;
}

.cbItem {
  text-align: start;
  color: var(--text-color);
  white-space: nowrap;
  max-width: -webkit-fill-available;
  text-overflow: ellipsis;
  overflow: hidden;
}

.itCbBtnGroupEdit {
  width: 35px;
  height: 35px;
  background: var(--edit-background-color);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 4px;
}
.itCbBtnGroupDelete {
  width: 35px;
  height: 35px;
  top: 315px;
  background: var(--delete-background-color);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 4px;
}

.itCbDetailsIcon {
  width: 24px;
  height: 24px;
  display: none;
}

.itCbDetails {
  border: 1px solid var(--border-color);
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-1);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem 1rem;
  margin-bottom: var(--mb-1);
}

.itCbDetailsActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: var(--mb-1);
  border-bottom: 1px solid var(--border-color);
}

.cbCbDetailsItem {
  border-bottom: 1px solid var(--border-color);
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: var(--mb-1);
}

.itCbDetailsEdit {
  width: 35px;
  height: 35px;
  background: var(--edit-background-color);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}


.itCbDetailsDelete {
  width: 35px;
  height: 35px;
  top: 315px;
  background: var(--delete-background-color);
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.detailStatus {
  border: 1px solid var(--first-color);
  width: 31px;
  height: 31px;
  border-radius: 28px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.status {
  border: 1px solid var(--first-color);
  margin-left: 12px;
  width: 31px;
  height: 31px;
  border-radius: 28px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 25px;
  height: 25px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 25px;
  border: none;
}

.notStatus {
  border: 1px solid var(--border-color);
  margin-left: 12px;
  width: 31px;
  height: 31px;
  border-radius: 28px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detailNotStatus {
  border: 1px solid var(--border-color);
  width: 31px;
  height: 31px;
  border-radius: 28px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconBox {
  width: 25px;
  height: 25px;
  background: var(--text-color-light);
  border: 1px solid var(--border-color);
  border-radius: 25px;
  border: none;
}

@media screen and (max-width: 768px) {
  .itCbBtnGroupEdit,
  .itCbBtnGroupDelete,
  .notStatus,
  .status {
    display: none;
  }

  .itCbDetailsIcon {
    display: block;
  }

  .cbTableItem {
    grid-template-columns: 2fr 1fr 30px;
  }
}

@media screen and (max-width: 480px) {
  .cbTableItem {
    grid-template-columns: 50% 1fr 1fr;
  }

  // .itCbBtnGroupEdit {
  //   display: none;
  // }
}
