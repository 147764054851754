.crHouseContainer {
  height: 100%;
  background: white;
  overflow: auto;
  transition: all 0.4s ease;
  z-index: var(--z-modal);
}

.crContent {
  height: 100%;
  overflow-y: auto;
}

.crContent::-webkit-scrollbar {
  width: 0;
}

.crTitle {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  display: block;
  margin-bottom: var(--mb-2);
}

.crFormWrap {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.crForm {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.crFormContent {
  overflow-y: auto;
  flex: 1;
}

.crFormContent::-webkit-scrollbar {
  display: none;
}

.crFormInput {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--mb-1-5);
  max-width: 450px;
}

.crFormTextarea {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mb-1-5);
}

.crValueTextarea {
  min-height: 110px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  padding: 14.03px 1rem;
}

.crValueInput {
  color: var(--text-color);
  width: 100%;
  height: 43px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0 1rem;
}

.crValuePostalCode {
  text-transform: uppercase;
}

.crProvince {
  text-transform: uppercase;
}

.crValueInput::-webkit-outer-spin-button,
.crValueInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.crValueInput:focus {
  outline: none;
}

.crTitleInput {
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
  margin-bottom: var(--mb-0-5);
  margin-right: 10px;
  width: 200px;
}

.crCountryCode {
  width: 55px;
  height: 43px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 10px;
  outline: none;
}

.hhCrButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;
}

.formButton {
  height: 46px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 0.25rem;
  color: white;
  padding: 0 1.5rem;
  // margin-top: var(--mb-1);
}

.saveButton {
  margin-right: 16px;
}

@media screen and (max-width: 480px) {
  .crHouseContainer {
    width: 100%;
  }
}
