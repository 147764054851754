.hhHederHouseHold {
  display: grid;
  // grid-template-columns: 2fr repeat(3, 2fr) 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 0.25rem;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: var(--mb-1);

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: var(--text-color);
    font-weight: var(--font-semi-bold);
    display: flex;
    justify-content: center;
  }
}

.hhActions {
  text-align: right;
}
.status {
  display: flex;
  justify-content: center;
  align-items: center;
}
