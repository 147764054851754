.itHhHdHouseHoldImg {
  display: flex;
  margin: auto;
  .img {
    box-shadow: 0 0px 4px rgb(40 37 37 / 20%);
    width: 120px;
    height: 120px;
    border-radius: 60px;
  }
}

.itHdTableBlock {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 0.25rem;
}

.itHhHdTableItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.itHhHdTableItem:last-child {
  margin-bottom: 0;
}

.itHhHdItemHeadWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--mb-1);

}

.itHhHdItemHouseHold {
  display: flex;
  flex-direction: row-reverse;

  .itHhHdBtnGroupEdit {
    width: 35px;
    height: 35px;

    background: rgba(237, 168, 66, 0.25);
    border-radius: 35px;
    padding-left: 8px;

    .itHdButtonEdit {
      margin-left: 3px;
      margin-top: 10px;
    }
  }
  .itHhHdBtnGroupDelete {
    width: 35px;
    height: 35px;

    background: rgba(213, 77, 74, 0.25);
    border-radius: 35px;
    padding-left: 8px;
    margin-left: 8px;

    .itHhHdButtonDelete {
      margin-top: 7px;
    }
  }
}
.itHhItemRequest {
  display: flex;
}
.itBorderIconCheck {
  border: 1px solid #0077b6;
  margin-right: 3px;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itBorderIconDelete {
  border: 1px solid gray;
  margin-right: 3px;
  width: 26px;
  height: 26px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itTbCheckboxCheck {
  width: 20px;
  height: 20px;
  background: #0077b6;
  border: 1px solid #0077b6;
  border-radius: 20px;
  border: none;
}

.itHhHdTitle {
  color: var(--text-color);
}

.itSpanItem {
  color: var(--text-color);
  margin-left: 4px;
}

.itTbCheckbox {
  width: 20px;
  height: 20px;
  background: gray;
  border: 1px solid #0077b6;
  border-radius: 25px;
  border: none;
}
.nameBoth {
  color: rgba(255, 159, 67, 1);
  background-color: rgba(255, 159, 67, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nameOther {
  color: rgba(130, 134, 139, 1);
  background-color: rgba(130, 134, 139, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nameInside {
  color: rgba(0, 119, 182, 1);
  background-color: rgba(0, 119, 182, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nameOutside {
  color: rgba(115, 103, 240, 1);
  background-color: rgba(115, 103, 240, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.itHhHdTitle {
  display: flex;
}
.itSpanItem {
  display: flex;
  flex-wrap: wrap;
}
.itSpanItemName {
  margin-bottom: 5px;
  margin-top: 5px;
}
.itHhHdTitle {
  margin-right: 4px;
}

.itHhHdTableItemService {
  margin-bottom: 1rem;
}

.itHhHdItemRequest {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .itHhHdBtnGroupEdit,
  .itHhHdBtnGroupDelete {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .itHhHdBtnGroupEdit {
    width: 35px;
    height: 35px;
    background-color: var(--edit-background-color);
    border-radius: 35px;
  }
  .itHhHdBtnGroupDelete {
    width: 35px;
    height: 35px;
    background-color: var(--delete-background-color);
    border-radius: 35px;
    margin-left: 0.5rem;
  }
}
