.umTableItem {
  display: grid;
  // grid-template-columns: repeat(2, 3fr) 1fr 2fr repeat(3, 1fr);
  // grid-template-columns: repeat(2, 3fr) 1fr 2fr repeat(3, 1fr);
  grid-template-columns: 30% 20% 25% 10% 15%;
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 4px;
  line-height: 50px;
  padding: 0 1rem;
  // column-gap: 1rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.flex {
  display: flex;
  align-items: center;
  justify-content: end;
  .icon-edit {
    width: 35px;
    height: 35px;
    top: 315px;
    background: var(--edit-background-color);
    border-radius: 35px;
    padding-left: 8px;

    cursor: pointer;
    .margin {
      margin-bottom: 5px;
      margin-left: 3px;
    }
  }
  .icon-delete {
    width: 35px;
    height: 35px;
    top: 315px;
    background: var(--delete-background-color);
    border-radius: 35px;
    padding-left: 8px;
    margin-left: 8px;

    cursor: pointer;
    .margin {
      margin-bottom: 2px;
    }
  }
}

.status {
  border: 1px solid var(--first-color);
  margin-left: 12px;
  width: 31px;
  height: 31px;
  border-radius: 28px;
  margin-top: 10px;
  .icon {
    width: 25px;
    height: 25px;
    background: var(--first-color);
    border: 1px solid var(--first-color);
    border-radius: 25px;
    border: none;
    margin-bottom: 3.3px;
    margin-left: 2px;
  }
}

.notStatus {
  border: 1px solid var(--border-color);
  margin-left: 12px;
  width: 31px;
  height: 31px;
  border-radius: 28px;
  margin-top: 10px;
  .iconBox {
    width: 25px;
    height: 25px;
    background: var(--text-color-light);
    border: 1px solid var(--border-color);
    border-radius: 25px;
    border: none;
    margin-bottom: 3.3px;
    margin-left: 2px;
  }
}

.umNameField {
  display: flex;
  align-items: center;
}

.umNameField,
.umEmailField {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 15px;
}

.imgUser {
  width: 35px;
  height: 35px;
  // box-shadow: 0 0px 4px rgb(40 37 37 / 20%);
  border: 1px solid var(--border-color);
  border-radius: 50%;
  object-fit: cover;
}

.umUserImg {
  display: flex;
  align-self: center;
  margin-right: 0.5rem;
}

.umPhoneNumber {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 14px;
}

.umNameValue {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70%;
}
.role {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
