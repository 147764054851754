.drDropdown {
  position: relative;
}

.drFlowDropdown {
  width: 100%;
  height: 43px;
  border-radius: 4px;
  cursor: pointer;
}

.drDropdownBtn {
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: var(--text-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
}

.drDownUp {
  width: 24px;
  height: 24px;
}

.drWrapContent {
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  margin-top: 1rem;
  background-color: var(--body-color);
  box-shadow: var(--text-color-light);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0.5rem;
  z-index: var(--z-tooltip);
}

.drItemOptions {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--text-color);
  padding: 0 1rem;
  font-weight: var(--font-medium);
  font-size: var(--normal-font-size);
  height: 46px;
  line-height: 46px;
  border-radius: 0.25rem;
  cursor: pointer;

  &:hover {
    color: var(--first-color);
    background-color: var(--first-color-light);
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--title-color);
  opacity: 0.6;
  z-index: var(--z-overlay);
}
.drItemSelected {
  color: var(--first-color);
  background: var(--first-color-light);
}
