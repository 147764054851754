.lWrapper {
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 0.25rem;
  // height: calc(100% - 160px);
  height: calc(100% - 99px);
}
.lFilters {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: var(--mb-1);
}
.lBtnAddHouseHold {
  padding: 0 1rem;
  height: 43px;
  background-color: var(--first-color);
  border-radius: 0.25rem;
  color: white;
  display: flex;
  align-items: center;

  .lPlus {
    font-size: 25px;
    margin-right: 5px;
  }
}

.buttonDisable {
  background-color: var(--border-color);
  border: none;
  color: white;
  cursor: not-allowed;
}

.lsTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-1);
}

.lCard {
  display: none;
}

.lCardDesktop {
  height: calc(100% - 61px);
}

.hhTabs {
  margin-bottom: var(--mb-1);
}

.lsHouseHoldsName {
  display: flex;
  align-items: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.lBntTab,
.lBntTabHousehold {
  border: none;
  width: 126px;
  height: 43px;
  border-radius: 0.25rem;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-right: 0.5rem;
}

.lBntTabHousehold {
  background-color: var(--first-color);
  color: var(--body-color);
  margin-right: 0.5rem;
}

.lBntTabHousehold:hover {
  background-color: var(--first-color-alt);
}

.lBntTab {
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
}

.lBntTab:hover {
  background-color: #eee;
}

.lWrapItem {
  height: calc(100% - 66px);
  overflow-y: auto;
}

.lWrapItem::-webkit-scrollbar {
  width: 0;
}

.lItem {
  overflow-y: overlay;
  height: calc(100vh - 410px);
}

.lPagination {
  position: fixed;
  bottom: 0;
  left: 260px;
  right: 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 1rem;
}

.lPagination #pnPagination {
  margin-top: 0;
}

.lLegend {
  display: flex;
  align-items: center;
}

.lLegendItem {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}

.lLegendItem span {
  color: var(--text-color);
  font-size: var(--small-font-size);
}

.lConnectedStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--connected-color);
  margin-right: 0.75rem;
}
.lDisconnectedStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--delete-color);
  margin-right: 0.75rem;
}
.lNotConnectStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--text-color-light);
  margin-right: 0.75rem;
}

// Responsive
.Cardnodedata {
  display: none;
}
@media screen and (max-width: 1024px) {
  .lCard {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 1rem;
  }
  .Cardnodedata {
    display: block;
  }

  // .lCardDesktop {
  //   display: none;
  // }

  .lWrapper {
    border: none;
    padding: 0;
  }

  .lPagination {
    position: unset;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .lPagination {
    flex-direction: column;
  }
  .Cardnodedata {
    display: block;
  }

  .lLegend {
    margin-bottom: var(--mb-1);
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 480px) {
  .lCard {
    grid-template-columns: 1fr;
  }
  .Cardnodedata {
    display: block;
  }

  .lFilters {
    position: relative;
    flex-direction: column-reverse;

    div {
      width: 100%;
      justify-content: center;
      margin-right: 0;

      button,
      ul {
        width: 100%;
      }
    }

    form {
      margin-bottom: var(--mb-1);
    }
  }

  .lsTitle {
    flex-direction: column;
  }

  .lsHouseHoldsName {
    margin-bottom: var(--mb-1);
  }

  .lBtnAddHouseHold {
    width: fit-content;
  }
}
