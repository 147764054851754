.itHhTableItem {
  display: grid;
  grid-template-columns: 2fr 2fr 2.5fr 2.5fr 1.5fr 1.5fr;
  column-gap: 1rem;
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 0.25rem;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: var(--mb-1);
  justify-items: flex-start;

  .itHhItemRequestActions {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .itHhBtnGroupEdit,
    .itHhBtnGroupDelete {
      width: 35px;
      height: 35px;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .itHhBtnGroupEdit {
      background-color: var(--edit-background-color);
      margin-right: 0.5rem;
    }

    .itHhBtnGroupDelete {
      background: var(--delete-background-color);
    }
  }

  p {
    color: var(--text-color);
  }

  .itHhItemRequest {
    display: flex;
    align-items: center;
    justify-content: center;

    .itBorderIconCheck {
      border: 1px solid var(--first-color);
      width: 29px;
      height: 29px;
      border-radius: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .itBorderIconDelete {
      border: 1px solid gray;
      margin-left: 12px;
      width: 31px;
      height: 31px;
      border-radius: 28px;
      margin-top: 10px;
    }
    .itTbCheckboxCheck {
      width: 25px;
      height: 25px;
      background: var(--first-color);
      border-radius: 25px;
    }

    .itTbCheckbox {
      width: 25px;
      height: 25px;
      background: gray;
      border: 1px solid var(--first-color);
      border-radius: 25px;
      border: none;
      margin-bottom: 3.3px;
      margin-left: 2px;
    }
  }

  .itDetails {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .itUp {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }

  .itHhTbRequest {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: -webkit-fill-available;
  }
}

.rqDetail {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  margin-bottom: var(--mb-1);
}

#rqDetailItemOne {
  display: grid;
  // grid-template-columns: repeat(3, 2fr) 95px 30px;
  grid-template-columns: 2fr 2fr 3fr 1.5fr 1fr 30px;
  column-gap: 1rem;
  border-bottom: 1px solid var(--border-color);
  height: 50px;
  align-items: center;
  padding: 0 1rem;
}

.itHhItemRequestActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .itHhBtnGroupEdit,
  .itHhBtnGroupDelete,
  .itHhBtnGroupEditHidden {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .itHhBtnGroupEdit {
    background-color: var(--edit-background-color);
    margin-right: 0.5rem;
  }

  .itHhBtnGroupEditHidden {
    margin-right: 0.5rem;
    cursor: default;
    user-select: none;
  }

  .itHhBtnGroupDelete {
    background: var(--delete-background-color);
  }
}

#rqDetailItemTow,
.rqDetailItem {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 1rem;
}
.rqDetailItem1 {
  width: 100%;

  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding: 1rem;
}

.rqDetailItem {
  border-bottom: none;
}

.rqDetailItemName {
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
  margin-bottom: 1rem;
}

.rqBntActive {
  width: 95px;
  height: 30px;
  background-color: rgb(226, 209, 95);
  line-height: 24px;
  border-radius: 4px;
  color: var(--body-color);
  font-size: 12px;
  cursor: text;
}

.rqBntIsActive {
  width: 95px;
  height: 30px;
  background-color: rgb(65, 128, 65);
  line-height: 24px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  cursor: text;
}

.rqWrapActive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rqDetailsIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.rqDetailValue {
  display: flex;
  flex-wrap: wrap;
  color: var(--text-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: -webkit-fill-available;
}

.rqTitleDetail {
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
}
.nameBoth {
  color: rgba(255, 159, 67, 1);
  background-color: rgba(255, 159, 67, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  margin-bottom: 1rem;
}
.nameOther {
  color: rgba(130, 134, 139, 1);
  background-color: rgba(130, 134, 139, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  margin-bottom: 1rem;
}
.nameInside {
  color: rgba(0, 119, 182, 1);
  background-color: rgba(0, 119, 182, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  margin-bottom: 1rem;
}
.nameOutside {
  color: rgba(115, 103, 240, 1);
  background-color: rgba(115, 103, 240, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  margin-bottom: 1rem;
}
.rqDetailItemThree1 {
  display: block;
}
.rqDetailItem3 {
  padding: 1rem;
}
.rqDetailItemThree3 {
  border-top: 1px solid rebeccapurple;
}
.rqDetailItem1 {
  border-top: 1px solid var(--border-color);
}
