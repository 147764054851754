.hhHederRequest {
  display: grid;
  // grid-template-columns: repeat(3, 2fr) 95px 30px;
  grid-template-columns: 2fr 2fr 2.5fr 2.5fr 1.5fr 1.5fr;
  column-gap: 1rem;
  border: 1px solid var(--border-color);
  height: 50px;
  line-height: 50px;
  border-radius: 0.25rem;
  padding: 0 1rem;
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-1);
  justify-items: flex-start;
}

.hhHeaderName {
  color: var(--text-color);
  font-weight: var(--font-semi-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hhHeaderName:nth-child(4) {
  text-align: center;
}

.hhHeaderName:nth-child(5) {
  text-align: right;
}
