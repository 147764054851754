.crHouseContainer {
  display: flex;
  flex-direction: column;
}

.itHhItemRequest {
  display: flex;
  justify-content: end;
  margin-bottom: 8px;
  height: 30px;
}

.rqOrderContent {
  height: 100%;
  overflow-y: auto;
}

.rqOrderHeader {
  display: flex;
  flex-direction: row;
  column-gap: 48px;
  border-bottom: 1px solid var(--first-color);
  padding: 0 16px;
  padding-bottom: 24px;
}

.rqOrderInfo {
  max-width: 33%;
}

.rqOrderTitle,
.rqWorkOrderTitle,
.rqOrderFooterTitle {
  margin-bottom: 16px;
  color: var(--text-color);
  font-weight: var(--font-semi-bold);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rqOrderTitle.mobile {
  display: none;
}

.rqWorkOrder {
  padding: 24px 16px;
  border-bottom: 1px solid var(--first-color);
}

.rqSubServices {
  color: var(--text-color);
  margin-left: 4px;
}

.rqSubServiceItem {
  margin-bottom: 5px;
  margin-top: 5px;
  width: fit-content;
}

.rqSubServiceName {
  color: rgba(0, 119, 182, 1);
  background-color: rgba(0, 119, 182, 0.12);
  height: 38px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rqOrderFooter {
  padding: 24px 16px;
}

.rqBntActive {
  width: 95px;
  height: 30px;
  background-color: rgb(226, 209, 95);
  line-height: 24px;
  border-radius: 4px;
  color: var(--body-color);
  font-size: 12px;
  cursor: text;
}

.rqBntIsActive {
  width: 95px;
  height: 30px;
  background-color: rgb(65, 128, 65);
  line-height: 24px;
  border-radius: 4px;
  color: white;
  font-size: 12px;
  cursor: text;
}

.orderButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
  height: 46px;
}

.orderBackButtonWrapper {
  display: flex;
  gap: 10px;
  color: #6c757d;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .rqOrderHeader {
    flex-direction: column;
  }

  .rqOrderInfo {
    display: flex;
    flex-direction: row;
    max-width: 100%;
  }

  .rqOrderTitle {
    margin-right: 16px;
    display: none;
  }

  .rqOrderTitle.mobile {
    display: block;
  }

  .rqOrderValue {
    flex: 1;
  }
}
