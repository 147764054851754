.lContainerWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lBlock {
  border: 1px solid #adb5db;
  padding: 16px;
  border-radius: 4px;
  flex: 1;
  overflow: hidden;
}

.lItemDesktop {
  height: 100%;
}

.lItemWrapper {
  height: 100%;
  overflow-y: scroll;
}

.lItemWrapper::-webkit-scrollbar {
  width: 0;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
}

.formButton {
  height: 46px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 0.25rem;
  color: white;
  padding: 0 1.5rem;
  // margin-top: var(--mb-1);
}

.backButtonWrapper {
  display: flex;
  gap: 10px;
  color: #6c757d;
  cursor: pointer;
}

.actionButtonWrapper {
  display: flex;
  gap: 10px;
}

@media screen and (max-width: 1024px) {
  .lBlock {
    border: none;
    padding: 0;
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .buttonContainer {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .backButtonWrapper {
    margin-top: 16px;
  }
}
