.pnPagination {
  display: flex;
  justify-content: flex-end;
  // margin-top: 1rem;
}

.pnNextPage {
  display: flex;
}

.pnDoubleDown,
.pnDown,
.pnUp,
.pnDoubleUp {
  border: 1px solid var(--border-color);
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: default;
}

.pnDoubleDown:hover,
.pnDown:hover,
.pnUp:hover,
.pnDoubleUp:hover {
  background-color: var(--first-color-light);
}

.pnDoubleDown,
.pnUp {
  margin-right: 0.5rem;
}
.pnUpMax,
.pnDoubleUpMax,
.pnDownMax,
.pnDoubleDownMax {
  border: 1px solid var(--border-color);
  background-color: var(--first-color);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 0.5rem;
  color: white;
}

.pnTransfer {
  height: 40px;
  line-height: 40px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  color: var(--text-color-light);
  margin: 0 1rem;
}

@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 920px) {
}
@media screen and (max-width: 645px) {
  .pnPagination {
    justify-content: center;
  }
}
@media screen and (max-width: 448px) {
  // .pnDoubleDown {
  //   margin-right: 0.1rem;
  // }
  // .pnUpMax {
  //   margin-right: 0.1rem;
  // }
  .pnTransfer {
    margin: 0 0.2rem;
    margin-right: 10px;
  }
  .pnUpMax,
  .pnDoubleUpMax,
  .pnDownMax,
  .pnDoubleDownMax {
    width: 35px;
    height: 40px;
  }
  .pnDoubleDown,
  .pnDown,
  .pnUp,
  .pnDoubleUp {
    width: 35px;
    height: 40px;
  }
}
@media screen and (max-width: 320px) {
}
