.noData {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.nameData {
  color: gray;
  font-weight: 500;
}
