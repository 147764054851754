.cnpTitle {
  margin-bottom: 50px;
}

.cnpText {
  font-size: 1.75rem;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  line-height: 42px;
  margin-bottom: var(--mb-1);
}

.cnpText span {
  color: var(--first-color);
}

.cnpDesc {
  font-size: 18px;
  font-weight: var(--font-medium);
  color: var(--text-color-light);
}

.cnpForgot {
  text-align: right;
  font-size: 0.75rem;
  font-weight: var(--font-medium);
  color: var(--text-color);
  margin-bottom: 50px;
  cursor: pointer;
}

.cnpField {
  margin-bottom: var(--mb-1);
}

.cnpRequired {
  color: var(--text-color);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.cnpField:nth-child(2) {
  margin-bottom: 50px;
}

.cnpInput {
  height: 50px;
  border: 1px solid var(--border-color) !important;
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
}

.cnpButton {
  width: 100%;
  height: 50px;
  background-color: var(--first-color) !important;
  color: var(--body-color) !important;
  font-weight: var(--font-semi-bold) !important;
  font-family: var(--body-font) !important;
  border-radius: 0.25rem;
  font-size: var(--small-font-size);
}

.cnpButton:hover {
  background-color: var(--first-color-alt) !important;
}

.cnpInput img {
  width: 22px !important;
  height: 22px !important;
}
.lg-forgot {
  color: black;
}

// Responsive
@media screen and (max-width: 992px) {
  .createNewPassword {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .cnpText {
    font-size: 1.25rem;
  }

  .cnpDesc {
    font-size: 1rem;
  }
}
