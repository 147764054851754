.hdTableHeader,
.itTableItem {
  display: grid;
  height: 50px;
  line-height: 50px;
  border-radius: 0.25rem;
  padding: 0 1rem;
  border: 1px solid var(--border-color);
}

.hdTableHeader {
  grid-template-columns: 6fr 1fr;
  column-gap: 1rem;
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
  color: var(--text-color);
}

.hdHeaderItem {
  color: var(--text-color);
  font-weight: var(--font-semi-bold);
}

.hdHeaderItem:last-child {
  text-align: right;
}
.itTableItem {
  grid-template-columns: 6fr 1fr;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.itItemActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.itActive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itBtnGroupEdit,
.itBtnGroupDelete {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.itBtnGroupEdit {
  background-color: var(--edit-background-color);
  margin-right: 0.5rem;
}

.itBtnGroupDelete {
  background-color: var(--delete-background-color);
}

.itTbService {
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}
.itTbServiceName {
  display: flex;
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}
.itBorderIconCheck {
  border: 1px solid var(--first-color);
  width: 31px;
  height: 31px;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itBorderIconDelete {
  border: 1px solid var(--border-color);
  width: 31px;
  height: 31px;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itTbCheckboxCheck,
.itTbCheckbox {
  width: 25px;
  height: 25px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 25px;
  border: none;
}

.itTbCheckboxCheck {
  background: var(--first-color);
  border: 1px solid var(--first-color);
}

.itTbCheckbox {
  background: var(--text-color-light);
  border: 1px solid var(--text-color-light);
}

.img {
  width: 35px;

  height: 35px;
  border-radius: 35px;
  object-fit: cover;
  border: 1px solid var(--border-color);
  max-width: none;
}
.itTbServiceImg {
  display: flex;
  margin-right: 0.5rem;
  width: 35px;
  max-width: none;
  height: 35px;
  border-radius: 35px;
}

.itTbService,
.nameService {
  max-width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.service1 {
  transform: translateX(100%);

  display: none;
  transition: 5s;
}
.service {
  display: block;
}
