.itTableItemWrap {
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.hdTableHeader,
.itTableItem {
  display: grid;
  height: 106px;
  line-height: 50px;
}

.hdTableHeader {
  grid-template-columns: 2fr repeat(6, 1fr);
  column-gap: 2rem;
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
  color: var(--text-color);
}

.hdHeaderItem {
  align-items: center;
  color: var(--text-color);
  font-weight: var(--font-semi-bold);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hdHeaderItem:last-child {
  text-align: center;
}

.hdHeaderItem:nth-child(6) {
  text-align: center;
}

.hdHeaderItem:nth-child(7) {
  text-align: right;
}

.itTableItem {
  display: flex;
  column-gap: 2rem;
}

.itItemActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.itActive {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itBtnGroupDelete {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.itBtnGroupEdit {
  background-color: var(--edit-background-color);
  margin-right: 0.5rem;
}

.itBtnGroupDelete {
  background-color: var(--delete-background-color);
}

.itTbService {
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}

.itTbServiceName {
  display: flex;
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  flex: 1;
}

.crWrapInput {
  width: 100%;
}

.wrapPrice {
  display: flex;
  align-items: center;
}

.itBorderIconCheck {
  border: 1px solid var(--first-color);
  width: 31px;
  height: 31px;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itBorderIconDelete {
  border: 1px solid var(--border-color);
  width: 31px;
  height: 31px;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itTbCheckboxCheck,
.itTbCheckbox {
  width: 25px;
  height: 25px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 25px;
  border: none;
}

.itTbCheckboxCheck {
  background: var(--first-color);
  border: 1px solid var(--first-color);
}

.itTbCheckbox {
  background: var(--text-color-light);
  border: 1px solid var(--text-color-light);
}

.img {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  object-fit: cover;
  border: 1px solid var(--border-color);
  max-width: none;
}
.itTbServiceImg {
  display: flex;
  margin-right: 0.5rem;
  width: 35px;
  max-width: none;
  height: 35px;
  border-radius: 35px;
}

.itTbService,
.nameService {
  max-width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  display: flex;
}

.crValueInput {
  border: none;
  outline: none;
  background: none;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
  width: 900%;
}

.crPriceInput {
  border: none;
  outline: none;
  background: none;
  color: var(--text-color);
  width: 100%;
}

.containerPriceInput {
  display: flex;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--border-color);
  max-width: 100px;
  height: min-content;
}

.unitPriceInput,
.crPriceWrap {
  line-height: normal;
}

.service1 {
  transform: translateX(100%);

  display: none;
  transition: 5s;
}

.service {
  display: block;
}

.crFormWrapCheckBox {
  display: flex;
  height: 25px;
}

.crFormCheckBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-right: 16px;
}

.crCheckedInput {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
  align-self: flex-end;
}

.itItemActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.itBtnGroupEdit {
  height: 30px;
  font-size: 14px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 12px;
  background-color: var(--edit-background-color);
}

.itBtnGroupEdit.disable {
  background-color: var(--border-color);
  color: white;
  cursor: not-allowed;
}

.deleteButton {
  background-color: var(--delete-background-color);
  color: #d9534f;
  margin: 0;
}

.crFormTextarea {
  margin: 0;
  padding: 12px 0;
  flex: 1;
}

.crValueTextarea {
  height: 100%;
  width: 100%;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  padding: 14.03px 1rem;
}

.itTableErrorItem {
  padding-bottom: 1rem;
  color: var(--delete-color);
  font-size: var(--small-font-size);
  line-height: normal;
}

.itTableErrorItem.tablet {
  display: none;
}

@media screen and (max-width: 768px) {
  .itTableItem {
    flex-direction: column;
    height: auto;
  }

  .crFormTextarea {
    max-width: 100%;
  }

  .itItemActions {
    padding-bottom: 1rem;
  }

  .itTableErrorItem.tablet {
    display: block;
  }

  .itTableErrorItem.desktop {
    display: none;
  }
}
