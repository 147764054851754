.lContainerWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.lBlock {
  border: 1px solid #adb5db;
  padding: 16px;
  border-radius: 4px;
  flex: 1;
  overflow: hidden;
}

.lFilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lItemDesktop {
  height: 100%;
}

.lItemWrapper {
  height: 100%;
  overflow-y: scroll;
}

.lItemWrapper::-webkit-scrollbar {
  width: 0;
}

.lService {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--mb-1);
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  color: var(--text-color);

  .lBtnAddService {
    padding: 0 1rem;
    height: 43px;
    background: var(--first-color);
    border-radius: 4px;
    color: white;
    display: flex;
    line-height: 43px;

    p {
      font-size: 25px;
      margin-right: var(--mb-0-5);
    }
  }

  .lBtnAddService:hover {
    background-color: var(--first-color-alt);
  }
}
.lServiceName {
  align-items: center;
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.lItemMob {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.lItem {
  overflow-y: overlay;
  height: calc(100vh - 367px);
}

.lIPagination {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  margin-top: 1rem;
}

.containerButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 48px;
  gap: 16px;
}

.actionButton {
  height: 46px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 0.25rem;
  color: white;
  padding: 0 1.5rem;
}

.buttonCancel {
  background-color: white;
  color: var(--first-color);
}

@media screen and (max-width: 1024px) {
  .lBlock {
    border: none;
    padding: 0;
    height: auto;
  }

  .lIPagination {
    position: unset;
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .lItemMob {
    grid-template-columns: 100%;
  }

  .lFilters {
    flex-direction: column-reverse;
    margin-top: 1rem;
  }
}
