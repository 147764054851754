.lsTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.lCardDesktop {
  height: 100%;
}

.cbListItem {
  height: calc(100% - 66px);
  overflow-y: auto;
}

.cbListItem::-webkit-scrollbar {
  width: 0;
}

.cbWrapper {
  height: 100%;
}

.cbTitleNameService {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.cbBtnAddCombineService {
  height: 43px;
  padding: 0 1rem;
  background: var(--first-color);
  border-radius: 4px;
  color: var(--body-color);
  display: flex;
  line-height: 43px;
  .cbPlus {
    font-size: 25px;
    margin-right: 0.5rem;
  }
}

.cbHeader {
  border: 1px solid #adb5db;
  padding: 1rem;
  border-radius: 4px;
  height: calc(100% - 98px);
  overflow: hidden;
}

.cbPagination {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
}

@media (min-width: 767px) and (max-width: 1024px) {
  .cbHeader {
    height: auto;
  }

  .cbPagination {
    position: unset;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .cbHeader {
    height: auto;
  }

  .cbPagination {
    position: unset;
    padding: 0;
  }
}

@media screen and (max-width: 375px) {
  .lsTitle {
    flex-direction: column;
    align-items: flex-start;
  }

  .cbTitleNameService {
    margin-bottom: var(--mb-1);
  }

  .cbBtnAddCombineService {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
