.crServiceContainerShow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  width: 450px;
  height: 100%;
  background: var(--body-color);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 24px 0px 0px 24px;
  overflow: auto;
  transform: translateX(0);
  transition: all 4s ease;
  z-index: var(--z-modal);
}

.crContent {
  height: 100%;
  overflow-y: auto;
}

.crContent::-webkit-scrollbar {
  width: 0;
}

.crBtnClose {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.crBtnClose img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.crWrapLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--mb-1-5);

  .crLogo {
    width: 120px;
    height: 120px;
    object-fit: cover;

    border-radius: 120px;
    // padding: 0.1rem;
  }
  .crLogo1 {
    width: 126px;
    height: 126px;

    border: 3px solid var(--first-color);
    border-radius: 120px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .crWrapIconEdit {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: var(--first-color);
    display: flex;
    align-items: center;
    justify-content: center;

    input[type='file'] {
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      opacity: 0;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
}

.crWrapAvatar {
  position: relative;
}

.crFormWrap {
  margin-top: var(--mb-1);

  .crTitle {
    font-size: var(--h3-font-size);
    font-weight: var(--font-semi-bold);
    color: var(--text-color);
    display: block;
    margin-bottom: var(--mb-2);
  }

  .crFormInput {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--mb-1-5);

    .crTitleInput {
      font-weight: var(--font-semi-bold);
      color: var(--text-color);
      margin-bottom: 0.5rem;
    }
  }
}

.crCheckedInput {
  width: 25px;
  height: 25px;
  margin-right: 16px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  cursor: pointer;
}

.crTitleCheckBox {
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
}

.crFormCheckBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: var(--mb-1-5);
}

.crValueInput {
  height: 46px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0 1rem;
  color: var(--text-color);
}

.crValueInput::-webkit-outer-spin-button,
.crValueInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.crValueInput:focus {
  outline: none;
}

.crWrapBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.btnCreate {
  width: 110px;
  height: 46px;
  background: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 0.25rem;
  color: white;
}

.crServiceContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  width: 450px;
  height: 100%;
  background: white;
  border-radius: 1.5rem 0px 0px 1.5rem;
  overflow: auto;
  transform: translateX(100%);
  transition: all 0.4s ease;
  z-index: var(--z-modal);
}

.crServiceContainerShow {
  transform: translateX(0);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--title-color);
  opacity: 0.6;
  z-index: var(--z-overlay);
}

.crFormTextarea {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mb-1-5);

  .crTitleTextarea {
    font-weight: var(--font-semi-bold);
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }
}

.crValueTextarea:focus {
  outline: none;
}

.crValueTextarea {
  min-height: 110px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  padding: 14.03px 1rem;
}

.crFormScroll {
  height: calc(100vh - 377px);
  overflow: auto;
}

.crFormScroll::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 480px) {
  .crServiceContainerShow {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: -webkit-fill-available;
    height: 100%;
    background: var(--body-color);
    box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
    border-radius: unset;
    overflow: auto;
    z-index: 1000;
    width: 320px;
  }
}
