.itTableItemWrap {
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.itTableItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  line-height: 50px;
}

.itItemActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.itBtnGroupEdit {
  height: 30px;
  font-size: 14px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 12px;
  background-color: var(--edit-background-color);
}

.itBtnGroupEdit.disable {
  background-color: var(--border-color);
  color: white;
  cursor: not-allowed;
}

.deleteButton {
  background-color: var(--delete-background-color);
  color: #d9534f;
  margin-right: 0.5rem;
}

.itTbService {
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}

.itTbServiceName {
  display: flex;
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
  flex: 1;
}

.itTbServiceImg {
  display: flex;
  margin-right: 0.5rem;
  width: 35px;
  max-width: none;
  height: 35px;
  border-radius: 35px;
}

.itTbService,
.nameService {
  max-width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}

.crValueInput {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
  width: 90%;
  max-width: 350px;
}

.itTableErrorItem {
  padding-bottom: 1rem;
  color: var(--delete-color);
  font-size: var(--small-font-size);
  line-height: normal;
}

.itTableErrorItem.tablet {
  display: none;
}

@media screen and (max-width: 768px) {
  .itTableItem {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0.75rem;
  }

  .itTbServiceName {
    width: 100%;
  }

  .crValueInput {
    width: 100%;
    max-width: 100%;
  }

  .itItemActions {
    width: 100%;
    align-items: end;
  }

  .itTableErrorItem.tablet {
    display: block;
  }

  .itTableErrorItem.desktop {
    display: none;
  }

  .itTableErrorItem {
    align-self: flex-start;
  }
}
