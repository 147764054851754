.itHhTableItem {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 0.25rem;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: var(--mb-1);

  .itHhItemHouseHold {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .itHhBtnGroupEdit,
    .itHhBtnGroupDelete {
      width: 35px;
      height: 35px;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .itHhBtnGroupEdit {
      background-color: var(--edit-background-color);
      margin-right: 0.5rem;
    }

    .itHhBtnGroupDelete {
      background: var(--delete-background-color);
    }
  }
  .itHhTbHouseHold {
    display: flex;
    align-items: center;
    .img {
      width: 25px;
      height: 25px;
      box-shadow: 0 0px 4px rgb(40 37 37 / 20%);
      border-radius: 20px;
      margin-right: 10px;
    }
  }

  .itItem {
    max-width: -webkit-fill-available;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-color);
    display: flex;
    justify-content: center;
  }
}

.itHhTableItem:hover {
  cursor: pointer;
}

.itHhTableItem:nth-child(11) {
  margin-bottom: 0;
}
.connectedStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--connected-color);
  // box-shadow: 0px 0px 5px #31a24c;
}
.notConnectStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--text-color-light);
  // box-shadow: 0px 0px 5px gray;
}
.disconnectStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: var(--delete-color);
  // box-shadow: 0px 0px 5px red;
}
.statusBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}
