.crUserContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 450px;
  padding: 2rem;
  height: 100%;
  background: var(--body-color);
  box-shadow: 0px 0px 6px rgb(0 0 0 / 30%);
  border-radius: 24px 0px 0px 24px;
  overflow: auto;
  transform: translateX(100%);
  transition: all 0.4s ease;
  z-index: 1000;
}

.crUserContainerShow {
  transform: translateX(0);
}

.crUserContainer::-webkit-scrollbar {
  width: 0;
}

.crContent {
  height: 100%;
  overflow-y: auto;
}

.crContent::-webkit-scrollbar {
  width: 0;
}

.crBtnClose {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.crBtnClose img {
  width: 100%;
  height: 100%;
}

.crBackground {
  width: 100vw;
  height: auto;
  position: fixed;
  background: var(--text-color);
  opacity: 0.6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
}

.crWrap {
  position: relative;
  //   width: 130px;
  //   height: 130px;
  //   text-align: center;
  //   margin: auto;
  //   margin-top: 30px;
  // }

  // .crBtnClose {
  //   color: var(--title-color);
  //   width: 35px;
  //   height: 35px;
  //   font-size: 18px;
  //   background-color: transparent;
  //   border: transparent;
  //   cursor: pointer;
  // =======
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--mb-1-5);
}

.crBtnClose img {
  width: 100%;
  height: 100%;
}

.crLogo {
  position: relative;
  width: 120px;
  height: 120px;
  padding: 0.1rem;
  object-fit: cover;
  border: 3px solid var(--first-color);
  border-radius: 50%;
  text-align: center;
}

.crWrapAvatar {
  position: relative;
}

.crEdit {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: var(--first-color);
  display: flex;
  align-items: center;
  justify-content: center;

  input[type='file'] {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    opacity: 0;
  }

  input[type='file']::-webkit-file-upload-button {
    cursor: pointer;
  }
}

.crValueInput {
  color: var(--text-color);
  width: 100%;
  height: 43px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0 1rem;
  outline: none;
}

.crValueEmail {
  text-transform: lowercase;
}

.crFormInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: var(--mb-1-5);
}

.crTitleInput {
  //   color: var(--title-color);
  //   font-weight: var(--font-semi-bold);
  //   margin: 8px 0px;
  //   font-size: 16px;
  //   line-height: 24px;
  // =======
  color: var(--text-color);
  margin-bottom: var(--mb-0-5);
  font-weight: var(--font-semi-bold);
}

.crCreateWrap {
  display: flex;
  justify-content: flex-end;
}

.crBtnCreate {
  background: var(--first-color);
  color: var(--body-color);
  line-height: 24px;
  border-radius: 8px;
  height: 46px;
  width: 110px;
}

.crBtnCreate:hover {
  background-color: var(--first-color-alt);
}

.crPhone {
  display: flex;
  flex-direction: column;
}

.crCountryCode {
  width: 55px;
  height: 43px;
  background: var(--body-color);
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 10px;
  outline: none;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 480px) {
  .crUserContainer {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
}
