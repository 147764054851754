.hdHeader {
  margin-bottom: 1rem;
  position: relative;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 30px;
}

.hdUserProfile {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hdUserProfileImg {
  width: 40px;
  height: 40px;
  border-radius: 40px;

  cursor: pointer;
  border: 1px solid var(--border-color);
  object-fit: cover;
}

.hdUsername {
  font-size: var(--normal-font-size);
  color: var(--text-color);
}

.hdIconDown {
  width: 24px;
  height: 24px;

  cursor: pointer;
}

.hdShowLogOut {
  position: absolute;
  top: 110%;
  right: 30px;
  width: 200px;
  padding: 0.5rem;
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 0px 2px 0px, rgba(60, 64, 67, 0.15) 0px 0px 3px 1px;
  border-radius: 0.5rem;
}

.hdLogOut {
  width: 100%;
  border: none;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  color: var(--text-color);
  border-radius: 0.25rem;
  padding: 0.6rem 1rem;
  transition: all 0.4s ease;
  cursor: pointer;
}

.hdLogOut:hover {
  background-color: var(--first-color-light);
  color: var(--first-color);
}

@media screen and (max-width: 1024px) {
  .hdHeader {
    position: fixed;
    background-color: #fff;
    padding: 0 1rem;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
    z-index: var(--z-tooltip);
  }

  .hdShowLogOut {
    top: 120%;
    right: 1rem;
  }
}
