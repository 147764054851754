.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--title-color);
  opacity: 0.6;
  z-index: var(--z-overlay);
}
@media screen and (max-width: 480px) {
  .overlay {
    display: none;
  }
}
