.fgTitle {
  margin-bottom: 50px;
}

.fgText {
  font-size: 1.75rem;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  line-height: 42px;
  margin-bottom: var(--mb-1);
}

.fgDesc {
  font-size: 18px;
  font-weight: var(--font-medium);
  color: var(--text-color-light);
}

.fgField {
  margin-bottom: 40px;
}

.fgRequired {
  color: var(--text-color);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-5);
}

.fgInput {
  height: 50px;
  border: 1px solid var(--border-color) !important;
  font-size: var(--normal-font-size);
  font-family: var(--body-font);
}

.fgButton {
  width: 100%;
  height: 50px;
  background-color: var(--first-color) !important;
  color: var(--body-color) !important;
  font-weight: var(--font-semi-bold) !important;
  font-family: var(--body-font) !important;
  border-radius: 0.25rem;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1-5);
}

.fgButton:hover {
  background-color: var(--first-color-alt) !important;
}

.fgLogin {
  text-align: center;
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.fgLoginLink {
  color: var(--first-color);
  cursor: pointer;
  font-weight: var(--font-semi-bold) !important;
}

// Responsive
@media screen and (max-width: 992px) {
  .forgotPassword {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .fgText {
    font-size: 1.25rem;
    line-height: normal;
  }

  .fgDesc {
    font-size: 1rem;
  }
}
