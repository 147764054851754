.dlWrapper {
  position: fixed;
  background-color: var(--text-color);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
  opacity: 0.6;
}
.dbBlock {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 300px;
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  text-align: center;
  z-index: 10001;
}
.dbDigLog {
  display: flex;
  justify-content: center;
}
.dlBnt {
  padding: 0.5rem 2rem;
  background-color: var(--first-color);
  color: var(--body-color);
  border-radius: 0.25rem;
}
.dlTitle {
  color: var(--title-color);
  margin-bottom: var(--mb-1-5);
}
