.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 30px;
  column-gap: 1rem;
  align-items: center;
  height: 100vh;
}

.content {
  width: 391px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lg-logo {
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  color: var(--title-color);
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  cursor: pointer;
}

.lg-logo-img {
  margin-right: var(--mb-1);
}

.right-side {
  height: 100%;
}

.lg-copy {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-size: 0.875rem;
  text-align: center;
  color: var(--text-color-light);
}

// Responsive
@media screen and (max-width: 992px) {
  .wrapper {
    grid-template-columns: 1fr;
    height: 100vh;
    min-width: 320px;
  }

  .content {
    align-items: center;
  }

  .lg-logo {
    margin-bottom: 20px;
    width: 100%;
  }

  .lg-copy {
    position: unset;
    margin-top: 20px;
    transform: unset;
  }

  .right-side {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 20px;
  }

  .content {
    width: 100%;
  }
}

@media screen and (max-height: 768px) {
  .lg-copy {
    position: unset;
    transform: translateX(0);
    margin-top: 1rem;
  }
}
