.wrapper {
  padding: 10px 15px;
}

.content {
  background-color: green;
}
.lBlock {
  border: 1px solid #adb5db;
  padding: 16px;
  border-radius: 4px;
}
.lFilters {
  display: flex;
  justify-content: space-between;
  height: 40px;
}
.lSubService {
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;
  .lBtnAddSubService {
    width: 196px;
    height: 43px;
    background: #0077b6;
    border-radius: 4px;
    color: white;
    display: flex;
    line-height: 43px;
    p {
      font-size: 25px;
      margin-left: 15px;
      margin-right: 6px;
    }
  }
}
.lItemMob {
  display: none;
}
@media (min-width: 568px) and (max-width: 920px) {
  .lItemMob {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 10px;
  }
  .lItemDesktop {
    display: none;
  }
}
@media (min-width: 921px) and (max-width: 1024px) {
  .lItemMob {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    margin-top: 10px;
  }
  .lItemDesktop {
    display: none;
  }
}
@media (max-width: 568px) {
  .lItemMob {
    display: block;
  }
  .lItemDesktop {
    display: none;
  }
  .lFilters {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    height: 110px;
  }
}
.lBntTab {
  border: none;
  width: 126px;
  height: 43px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: white;
  color: gainsboro;
}
.lBntTabHousehold {
  border: none;
  width: 126px;
  height: 43px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #0077b6;
  color: white;
}
