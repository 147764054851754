.itHdTableBlock {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 0.25rem;

  .itHdTableItem {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .itHdServiceImg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: var(--mb-1);
  }
  .itHdItemActions {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: var(--mb-1);

    .itHdBtnGroupEdit {
      width: 35px;
      height: 35px;
      background: rgba(237, 168, 66, 0.25);
      border-radius: 35px;
      padding-left: 8px;
      cursor: pointer;
      .itHdButtonEdit {
        margin-left: 3px;
        margin-top: 10px;
      }
    }
    .itHdBtnGroupDelete {
      width: 35px;
      height: 35px;

      background: rgba(213, 77, 74, 0.25);
      border-radius: 35px;
      padding-left: 8px;
      margin-left: 8px;
      cursor: pointer;
      .itHdButtonDelete {
        margin-top: 7px;
      }
    }
  }
  .itBorderIconCheck {
    border: 1px solid var(--first-color);
    margin-left: 12px;
    width: 31px;
    height: 31px;
    border-radius: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .itBorderIconDelete {
    border: 1px solid gray;
    margin-left: 12px;
    width: 31px;
    height: 31px;
    border-radius: 28px;
  }

  .itTbCheckboxCheck {
    width: 25px;
    height: 25px;
    background: var(--first-color);
    border: 1px solid #0077b6;
    border-radius: 25px;
    border: none;
  }
  .itTbCheckbox {
    width: 25px;
    height: 25px;
    background: gray;
    border: 1px solid var(--first-color);
    border-radius: 25px;
    border: none;
    margin-top: 2.2px;
    margin-left: 2px;
  }
}

.itName {
  color: var(--text-color);
}

.itTableItem {
  max-width: -webkit-fill-available;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
  color: var(--text-color);
}

.itHdImg {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  object-fit: cover;
  border: 1px solid var(--border-color);
}
