.adminLayout {
  width: 100%;
  display: flex;
  height: 100vh;
}

.container {
  position: relative;
  left: 260px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 260px);
}

.content {
  padding: 0 2rem 2rem;
  min-width: 320px;
  height: calc(100% - 92px);
}

@media screen and (max-width: 1024px) {
  .sideBar {
    display: none;
  }

  .container {
    left: 0;
    width: 100%;
  }

  .content {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding: 0 1rem 1rem;
    height: auto;
  }
}
