.dlWrapper {
  position: fixed;
  background-color: var(--text-color);
  left: 0px;
  bottom: 0px;
  top: 0px;
  right: 0px;
  z-index: var(--z-modal);
  opacity: 0.6;
  overflow: hidden;
}
.dbBlock {
  position: fixed;
  padding: 0 2rem;
  height: 133px;
  background-color: white;
  border-radius: 8px;
  z-index: var(--z-modal);
  margin: auto;
  text-align: center;
  top: 40%;
}
.dbDigLog {
  display: flex;
  justify-content: center;
}
.dlBnt {
  width: 98px;
  height: 38px;
  background-color: var(--first-color);
  color: var(--body-color);
  border-radius: 4px;
  margin-right: 10px;
}
.dlBntCancel {
  width: 98px;
  height: 38px;
  background-color: white;
  color: var(--first-color);
  border: 1px solid var(--first-color);
  border-radius: 4px;
  margin-right: 10px;
}
.dlTitle {
  margin-top: 23px;
  margin-bottom: 24px;
}
