.lCardDesktop {
  height: 100%;
}

.crTitle {
  margin-bottom: var(--mb-1);
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.crBtnAddCombineService {
  width: 196px;
  height: 43px;
  background: var(--first-color);
  border-radius: 0.25rem;
  color: white;
  display: flex;
  line-height: 43px;
  .crPlus {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 12px;
  }
}

.crContentCombine {
  min-height: 500px;
  height: calc(100% - 39px);
}
