.s-search-form {
  width: 306px;
  background: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;

  .s-search-form-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 4px;
    background-color: #ffffff;
    height: 43px;
    .s-search-form-btn {
      width: 43px;
      height: 43px;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .s-search-form-input-field {
      outline: none;
      border: none;
      border-radius: 0.25rem;
      width: 100%;
      font-size: 1rem;
      color: #332e2e;
      height: 43px;
    }
  }
}
// Responsive mobile lower

@media screen and (max-width: 480px) {
  .s-search-form {
    width: 100%;
    margin-bottom: 1rem;
  }
}
