.pnfWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pnfContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnfImg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
  z-index: -1;
}

.pnfError {
  font-size: 100px;
  color: var(--first-color);
  font-weight: var(--font-bold);
  animation-name: transform;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.pnfTitle {
  font-size: 52px;
  color: var(--text-color);
  margin-bottom: 40px;
  text-align: center;
}

.pnfDesc {
  font-size: var(--h3-font-size);
  text-align: center;
  line-height: 2rem;
  color: var(--text-color-light);
  margin-bottom: 50px;
}

.pnfBtnHomePage {
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  background-color: var(--first-color);
  color: #fff;
  font-size: var(--normal-font-size);
  transition: all 0.4s ease;
}

.pnfBtnHomePage:hover {
  background-color: var(--first-color-alt);
}

@keyframes transform {
  0% {
    transform: translateX(-35%);
  }
  100% {
    transform: translateX(35%);
  }
}

// Responsive
@media screen and (max-width: 768px) {
  .pnfContent {
    width: 100%;
    padding: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .pnfTitle {
    font-size: 40px;
  }
}

@media screen and (max-width: 320px) {
  .pnfWrapper {
    width: 320px;
  }

  @keyframes transform {
    0% {
      transform: translateX(-25%);
    }
    100% {
      transform: translateX(25%);
    }
  }
}
