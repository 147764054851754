.login-wrapper {
  // height: calc(100vh - 60px);
  height: 100%;
  border-radius: 1.5rem;
  overflow: hidden;
}

.login-img {
  height: 100%;
  object-fit: cover;
}
