.ftFilterWrapper {
  position: relative;
  margin-right: 16px;
  cursor: pointer;
}
.ftFilterWrapperCombine {
  position: relative;
  cursor: pointer;
}

.ftFilterDropdownSelected {
  color: var(--first-color) !important;
  background-color: var(--first-color-light);
}

.ftOverflowHiddenMin {
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0 1rem;
  width: 175px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ftFilterWrapperMin {
  width: 100%;
  background-color: white;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ftOverflowHidden {
  width: 180px;
  height: 43px;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}
.ftOverflowHiddenCombine {
  width: 100%;
  height: 50px;
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  margin-bottom: var(--mb-1);
  border-radius: 0.25rem;
}

.ftFilterBtn {
  width: 100%;
  background-color: white;
  border: none;
  display: flex;
  justify-content: space-between;
}

.ftBlock {
  color: var(--text-color);
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ftFilterDropdownWrapper {
  position: absolute;
  width: 100%;
  margin-top: var(--mb-1);
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0.5rem;
  text-transform: capitalize;
  z-index: var(--z-tooltip);
  overflow-y: scroll;
  max-height: 50vh;

  li {
    list-style: none;
    text-align: left;
    line-height: 24px;
    padding: 8px 14px;
    width: 100%;
    border-radius: 0.25rem;
    color: var(--text-color);

    &:hover {
      background-color: var(--first-color-light);
      color: var(--first-color);
    }
  }

  li + li {
    margin-top: 8px;
  }
}
.ftFilterDropdownWrapper::-webkit-scrollbar {
  width: 4px;
}
.ftFilterDropdownWrapperCombine {
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 0.5rem;
  text-transform: capitalize;
  z-index: var(--z-tooltip);
  overflow-y: auto;
  max-height: 350px;

  li {
    list-style: none;
    text-align: left;
    line-height: 24px;
    padding: 8px 14px;
    width: 100%;
    border-radius: 0.25rem;
    color: var(--text-color);

    &:hover {
      background-color: var(--first-color-light);
      color: var(--first-color);
    }
  }

  li + li {
    margin-top: 8px;
  }
}

.ftFilterDropdownWrapperCombine::-webkit-scrollbar {
  width: 4px;
}

.ftIconDownUp,
.ftIconUpDown {
  width: 24px;
  height: 24px;
}

.ftHeaderIcon {
  height: 20px;
}

@media screen and (max-width: 480px) {
  .ftFilterWrapper {
    width: 100%;
    margin-right: 0;
  }

  .ftOverflowHidden,
  .ftFilterBtn {
    width: 100%;
  }
}
