.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--mb-1);
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.btnAdd {
  padding: 0 1rem;
  height: 43px;
  background: var(--first-color);
  border-radius: 4px;
  color: white;
  display: flex;
  line-height: 43px;
  p {
    font-size: 25px;
    margin-right: var(--mb-0-5);
  }
}

.filter {
  display: flex;
  justify-content: flex-end;
  height: auto;
}

.btnFilter {
  width: 110px;
  height: 43px;
  border-radius: 4px;
  line-height: 43px;
  background-color: white;
  border: 1px solid #e5e7eb;
  display: flex;
  padding: 11px 21px;
  .iconFilter {
    align-self: center;
    margin-right: 10px;
  }
  .iconNameFilter {
    align-self: center;
  }
}

.wrap {
  border: 1px solid #adb5db;
  padding: 16px;
  border-radius: 4px;
  height: calc(100% - 98px);
  overflow: hidden;
}

.tab {
  display: none;
}

.lIPagination {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 1.5rem;
  margin-top: 1rem;
}

.desk {
  height: calc(100% - 45px);
}

.item {
  height: calc(100% - 39px);
  overflow-y: overlay;
}

.item::-webkit-scrollbar {
  width: 0;
}

@media screen and (max-width: 1024px) {
  .tab {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }
  .desk {
    display: none;
  }

  .wrap {
    border: none;
    height: auto;
    padding: 16px 0 0;
  }

  .lIPagination {
    position: unset;
    padding: 0;
  }

  .user {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 768px) {
  .tab {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media screen and (max-width: 480px) {
  .tab {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 0;
  }
  .filter {
    flex-direction: column;
  }
  .btnFilter {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 320px) {
}
