.lWrapper {
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 0.25rem;
  // height: calc(100% - 160px);
  height: calc(100% - 99px);
  overflow: hidden;
}

.lBtnAddHouseHold {
  width: 196px;
  height: 43px;
  background: #0077b6;
  border-radius: 4px;
  color: white;
}
.lLegend {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lsTitle {
  display: flex;
  justify-content: space-between;
  height: 43px;
  line-height: 43px;
  margin-bottom: var(--mb-1);
}

.lBntTab,
.lBntTabRequests {
  font-size: var(--normal-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  height: 43px;
  border-radius: 4px;
}

.lsTabs {
  margin-bottom: var(--mb-1);
}

.lBntTab {
  margin-right: 0.5rem;
}

.lBntTab:hover {
  background-color: #eee;
}

.lBntTabRequests {
  background-color: var(--first-color);
  color: var(--body-color);
}

.lBntTabRequests:hover {
  background-color: var(--first-color-alt);
}

.lBtnFilter {
  height: 43px;
  padding: 0 1rem;
  border-radius: 0.25rem;
  background-color: var(--body-color);
  border: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.lqFilterBlock {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 1rem;
}

.lsRequestsName {
  font-size: var(--h3-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.lqDateInput {
  border: none;
  border-radius: 0.25rem;
  outline: none;
  color: var(--text-color);
}

.lqDateBlockTime {
  border: 1px solid var(--border-color);
  height: 39px;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.iconFilter {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.iconNameFilter {
  line-height: normal;
  color: var(--text-color-light);
}

.lItemMob {
  margin-top: 1rem;
  display: none;
  column-gap: 1rem;
  row-gap: 1rem;
}

.lItem {
  height: calc(100% - 66px);
  overflow-y: auto;
}

.lItemFilter {
  height: calc(100% - 155px);
}

.lItem::-webkit-scrollbar {
  width: 0;
}

.lItemDesktop {
  height: calc(100% - 59px);
}

.lPagination {
  position: fixed;
  bottom: 0;
  left: 260px;
  right: 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}
.lConnectedStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(0, 119, 182, 1);
  margin-right: 0.75rem;
}
.lDisconnectedStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(115, 103, 240, 1);
  margin-right: 0.75rem;
}
.lNotConnectStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(255, 159, 67, 1);
  margin-right: 0.75rem;
}
.OtherConnectStatus {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: rgba(130, 134, 139, 1);
  margin-right: 0.75rem;
}
.lLegendItem {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}

.Inside {
  color: rgba(0, 119, 182, 1);
  font-size: var(--small-font-size);
}
.Outside {
  font-size: var(--small-font-size);
  color: rgba(115, 103, 240, 1);
}
.Both {
  font-size: var(--small-font-size);
  color: rgba(255, 159, 67, 1);
}
.Other {
  font-size: var(--small-font-size);
  color: rgba(130, 134, 139, 1);
}

@media screen and (max-width: 1024px) {
  .lWrapper {
    height: auto;
    border: none;
    padding: 0;
  }

  .lPagination {
    position: unset;
    padding: 0;
  }

  .lItemMob {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .lqFilterBlock {
    flex-direction: column;
    row-gap: 1rem;
    align-items: flex-start;
  }

  .lqFilterBlock #ftFilter {
    width: 100%;
  }

  .lqDateBlockTime {
    width: 100%;
  }
}

@media (min-width: 720px) and (max-width: 920px) {
  .itHhHdTableItem {
    flex-direction: column;
  }

  .lItemDesktop {
    display: none;
  }

  .lqDateInput {
    width: 145px;
  }
}

@media (min-width: 921px) and (max-width: 1024px) {
  .lItemDesktop {
    display: none;
  }

  .lPagination {
    position: unset;
    padding: 0;
  }
}

@media (max-width: 720px) {
  .lItemDesktop {
    display: none;
  }

  .lqDateInput {
    width: 100%;
  }

  .itSpanItem {
    margin-top: 15px;
  }
}
@media (max-width: 625px) {
  .lPagination {
    display: flex;
    flex-direction: column;
  }
  .lLegend {
    margin-bottom: 10px;
  }
}
