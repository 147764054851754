.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

.tab button.active {
  border-bottom: 1px solid var(--first-color);
  color: var(--first-color);
}

.tabContent {
  display: none;
  margin-top: 32px;
  overflow-y: auto;
  height: 100%;
}

.tabContent.active {
  display: block;
}

@media screen and (max-width: 1024px) {
  .tabContent {
    overflow-y: hidden;
  }
}
