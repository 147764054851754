.umTableHeader {
  display: grid;
  // grid-template-columns: repeat(2, 3fr) 1fr 2fr repeat(3, 1fr);
  grid-template-columns: 30% 20% 25% 10% 15%;
  border: 1px solid var(--border-color);
  height: 50px;
  border-radius: 4px;
  line-height: 50px;
  padding: 0 1rem;
  margin: var(--mb-1) 0;

  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

.umActions {
  text-align: end;
}

.umPhoneNumber {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
