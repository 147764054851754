.cmTitle {
  margin-bottom: 50px;
}

.cmText {
  font-size: 1.75rem;
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
  line-height: 42px;
  margin-bottom: var(--mb-1);
}

.cmDesc {
  font-size: 18px;
  font-weight: var(--font-medium);
  color: var(--text-color-light);
}

.cmButton {
  width: 100%;
  height: 43px;
  background-color: var(--first-color);
  color: var(--body-color);
  border-radius: 0.25rem;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-1-5);
}

.cmButton:hover {
  background-color: var(--first-color-alt);
}

.fgResend {
  text-align: center;
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
}

.cmBtnResend {
  margin-left: var(--mb-0-5);
  color: var(--first-color);
  background-color: var(--body-color);
}

// Responsive
@media screen and (max-width: 992px) {
  .checkMail {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .cmText {
    font-size: 1.25rem;
    line-height: normal;
  }

  .cmDesc {
    font-size: 1rem;
  }
}
