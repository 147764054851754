a.active {
  color: var(--first-color);
}

.sbWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  background-color: var(--body-color);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px 24px 24px 0px;
  transition: all 0.4s ease-out;
  z-index: var(--z-fixed);
}

.sbLogo {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding: 0 2rem;
  margin-bottom: var(--mb-1);
  cursor: pointer;
}

.right-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.top-logo {
  font-size: 1.25rem;
  color: var(--first-color);
}

.bottom-logo {
  font-size: var(--smaller-font-size);
  font-weight: var(--font-medium);
}
.sbLogoImg {
  display: flex;
  align-self: center;
  height: 40px;
  margin-right: 1rem;
}

.li {
  display: flex;
  align-items: center;
  height: 43px;
  padding: 0 1.25rem;
  margin: 0 1rem 1rem;
}

.li > span {
  // display: flex;
  // align-items: end;
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.img-li {
  width: 18px;
  height: 20px;
  margin-right: 17px;
}

.active > li {
  background-color: #e4eaff;
  border-radius: 8px;
  color: var(--first-color);
}

.not-active > li {
  color: var(--text-color);
}

.sbHumbugger {
  position: fixed;
  top: 0.75rem;
  right: -2.5rem;
  cursor: pointer;
  z-index: var(--z-tooltip);
  z-index: 1;
  display: none;
}

.sbHumbuggerIcon {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sbShowSideBar {
  transform: translateX(0) !important;
}

.sbOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--title-color);
  opacity: 0.6;
  z-index: 99;
}

.sbVersion {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  font-size: var(--small-font-size);
  color: var(--text-color);
}

@media screen and (max-width: 1024px) {
  .sbWrapper {
    transform: translateX(-100%);
  }

  .sbHumbugger {
    display: block;
  }

  .sbLogo {
    height: calc(var(--header-height) + 1.5rem);
  }
}
